import { distanceBetween, DIRECTIONS_POINTER, calculatePercentageAway } from '../lib/helpers.js';

export default class Guess {
    constructor({name, location, answer, date, gameMode, seedText = null}) {
        this.name = name;
        this.location = location;
        this.distance = -1;
        this.percentage = -1;
        this.answerFound = false;
        this.emoji = '';
        this.calcDistanceToTarget(answer);
        this.calcPercentageGuess();
        this.calculateDirection(answer);
        this.date = date,
        this.gameMode = gameMode;
        this.seedText = seedText;
    }

    calculateDirection(answer) {
        // portugal tester = http://127.0.0.1:8080/?debug&seed=7261301922704997
        const xDelta = parseFloat(answer.lat) - parseFloat(this.location.lat);
        const yDelta = parseFloat(answer.lng) - parseFloat(this.location.lng);

        const radians = Math.atan2(yDelta,  xDelta);
        const compassReading = radians * (180 / Math.PI);

        const coordNames = ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"];
        let coordIndex = Math.round(compassReading / 45);

        if (coordIndex < 0) {
            coordIndex = coordIndex + 8
        };

        this.direction = coordNames[coordIndex];
    }

    calcDistanceToTarget(answer) {
        this.distance = distanceBetween(this.location, answer);
        this.distanceUnit = 'km';
    }

    calcPercentageGuess() {
        if (this.distance === 0) {
            this.percentage = 100;
            this.answerFound = true;
        }
        else {
            this.percentage = calculatePercentageAway(this.distance);
        }
    }

    calcDirection() {
        return "N";
    }

    calcEmoji() {
        this.emoji = (distanceToTarget === 0 ? 'Yay' : DIRECTIONS_POINTER[this.direction]);
    }
}

export function guessRating(percentage) {
    if (percentage < 20) return 'poor';
    else if (percentage >= 20 && percentage < 50) return 'good';
    else if (percentage >= 50 && percentage < 85) return 'great';
    else if (percentage >= 85) return 'excellent';
}

export function guessEmojiRating(rating) {
    switch (rating) {
        case 'poor':
            return '🟥';
        case 'good':
            return '🟧';
        case 'great':
            return '🟨';
        case 'excellent':
            return '🟩';
        default:
            return '⬜️';
    }
}

const STORAGE_GUESSES_ITEM_NAME = 'FlaggleGuesses';

export function saveGuessesData(guesses) {
    localStorage.setItem(STORAGE_GUESSES_ITEM_NAME, JSON.stringify(guesses));
}

export function loadGuessesData() {
    const data = localStorage.getItem(STORAGE_GUESSES_ITEM_NAME);
    return data == null ? [] : JSON.parse(data);
}

export function clearGuessesData() {
    localStorage.removeItem(STORAGE_GUESSES_ITEM_NAME);
}