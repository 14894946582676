<template>
    <div class="guess" :aria-label="guessAriaLabel">
        <template v-if="hasGuessed && guess">
            <span class="guess__country">{{ guess.name }}</span>
            <div>
                <span class="" v-if="!guess.answerFound" :aria-label="directionAriaLabel">{{ guess.direction |
                    directionEmoji}}</span>
                <span v-else>✨</span>
                <span class="guess__distance">{{ guess.distance | displayDistance }}km <span class="sr-only">away</span></span>
                <span class="guess__percentage" :class="getPercentageClass" aria-label="Guess rating">{{ guess.percentage }}%</span>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { numberWithCommas, DIRECTIONS_POINTER, DIRECTIONS_LABEL } from '../lib/helpers';
    import { guessRating } from '../lib/guess';

    export default {
        props: ['guess'],
        data: () => ({
        }),
        filters: {
            displayDistance(val) {
                return numberWithCommas(val);
            },

            directionEmoji(val) {
                return DIRECTIONS_POINTER[val];
            }
        },
        computed: {
            ...mapState('GameManager', [
                'answer'
            ]),

            directionAriaLabel() {
                return `Correct location is ${DIRECTIONS_LABEL[this.guess.direction]} from this guess`;
            },

            guessAriaLabel() {
                if (this.hasGuessed == false) {
                    return 'Empty guess';
                }

                if (this.guess.answerFound) {
                    return `Guess Correct`;
                }

                return `Guess incorrect. ${this.getPercentageClass} rating.`;
            },

            hasGuessed() {
                return this.guess != null;
            },

            getPercentageClass() {
                return guessRating(this.guess.percentage);
            },
        }
    }
</script>

<style lang="scss">
    .guess {
        background-color: #3e4058;
        background-color: #2d2f45;
        color: white;
        font-weight: 600;
        border-radius: 5px;
        padding: 7px 10px;
        min-height: 38px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        &__country {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
            min-width: 0;
        }

        &__distance {
            margin-left: 5px;
            display: inline-block;
        }

        &__percentage {
            margin-left: 5px;
            min-width: 40px;
            text-align: right;
            display: inline-block;

            &.excellent {
                color: #4ecf1a;
            }

            &.great {
                color: #f2f51d;
            }

            &.good {
                color: #e9a11d;
            }

            &.poor {
                color: #fc4242;
            }
        }
    }
</style>