export const GameMode = Object.freeze({
    NAME_TO_FLAG: 0,
    FLAG_TO_NAME: 1,
});

export const FlagMode = Object.freeze ({
    FLAG: 0,
    NAME: 1,
});

export const GuessState = Object.freeze({
    NOT_GUESSED: 0,
    HAS_GUESSED: 1,
});

export const distanceBetween = (a, b) => {
    var R = 6371; // Radius of the earth in km

    let lat1 = a.lat
    let lng1 = a.lng

    let lat2 = b.lat
    let lng2 = b.lng

    let dLat = (lat2 - lat1) * Math.PI / 180;
    let dLon = (lng2 - lng1) * Math.PI / 180;

    let d =
       0.5 - Math.cos(dLat)/2 +
       Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
       (1 - Math.cos(dLon))/2;

    return Math.floor(R * 2 * Math.asin(Math.sqrt(d)))
}

const MAX_EARTH_DISTANCE = 12756;

export const calculatePercentageAway = (distance) => {
    const prox = Math.max(MAX_EARTH_DISTANCE - distance, 0);
    return Math.floor((prox / MAX_EARTH_DISTANCE) * 100);
}

export const randomBetween = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min;
}

export const kmToMiles = (i) => {
    return Math.round(i * 0.621371)
}

export const numberWithCommas = (num) => {
    return num.toLocaleString('en', {maximumSignificantDigits : 21})
}

export const DIRECTIONS_POINTER = { 
    N: "⬆️",
    NE: "↗️",
    E: "➡️",
    SE: "↘️",
    S: "⬇️",
    SW: "↙️",
    W: "⬅️",
    NW: "↖️",
}

export const DIRECTIONS_LABEL = {
    N: "North",
    NE: "North East",
    E: "East",
    SE: "South East",
    S: "South",
    SW: "South West",
    W: "West",
    NW: "North West",
}

export const daysSinceStart = () => {
    const startDate = new Date('2022/03/26'); // Start date of flaggle
    const now = new Date();
    const deltaTime = now.getTime() - startDate.getTime();
    const deltaDays = deltaTime / 86400000;
    return Math.floor(deltaDays) + 1;
}

// Note this is not zero index based
export const getTodaysDateString = () => {
    const now = new Date();
    return `${now.getDate()}-${(now.getMonth()+1)}-${now.getFullYear()}`;
}
