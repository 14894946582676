var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "App" } },
    [
      _vm.infoPanelShowing ? _c("InfoPanel") : _vm._e(),
      _vm._v(" "),
      _vm.statsPanelShowing ? _c("StatsPanel") : _vm._e(),
      _vm._v(" "),
      _c("Header"),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "container" },
        [
          _vm.answer
            ? _c("Flag", { attrs: { country: _vm.answer } })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(this.allowedGuessCount, function (index) {
            return _c("GuessAnswer", {
              key: index,
              attrs: { guess: _vm.guesses[index - 1] || null },
            })
          }),
          _vm._v(" "),
          _c("GuessInput"),
          _vm._v(" "),
          _vm.gameMode == "seed" && _vm.seedText
            ? _c(
                "span",
                {
                  staticClass: "seed-value",
                  on: {
                    click: function ($event) {
                      return _vm.copySeedValue()
                    },
                  },
                },
                [_vm._v("Seed: " + _vm._s(_vm.seedText))]
              )
            : _c("span", { staticClass: "game-state" }, [
                _vm._v("#" + _vm._s(_vm.getDaysSinceStart())),
              ]),
          _vm._v(" "),
          _vm.showDebugMenu ? _c("DebugMenu") : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }